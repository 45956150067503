import * as R from 'ramda';
import axios from 'axios';
import { sessionManagement } from './auth';
import backend from './backend';

const headers = { crossdomain: true };
const url = backend();

const getHeaders = () => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: { ...headers, Authorization: `Bearer ${token}` },
  };
  return config;
};

export const GET_DOCUMENT = (urlDocument) => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  return axios.get(urlDocument, config);
};

export const GET_ESTUDIANTES = ({ offset, limit, filters }) => {
  const headers = getHeaders();
  const params = { offset, limit, ...filters };
  return axios.get(`${url}/estudiantes`, { ...headers, params });
};

export const GET_MATERIAS = () => {
  const headers = getHeaders();
  return axios.get(`${url}/materias`, headers);
};

export const GET_STATUS_LIST = () => {
  const headers = getHeaders();
  return axios.get(`${url}/admin/estudiantes/statuses`, headers);
};

export const GET_ALL_MATERIAS = () => {
  const headers = getHeaders();
  return axios.get(`${url}/materias/all`, headers);
};

export const POST_MATERIA = (data) => {
  const headers = getHeaders();
  return axios.post(`${url}/materias`, { data }, headers);
};

export const PUT_MATERIA = (clave, data) => {
  const headers = getHeaders();
  return axios.put(`${url}/materias/${clave}`, { data }, headers);
};

export const DELETE_MATERIA = (clave) => {
  const headers = getHeaders();
  return axios.delete(`${url}/materias/${clave}`, headers);
};

export const GET_ESTADISTICAS = () => {
  const headers = getHeaders();
  return axios.get(`${url}/admin/estadisticas`, headers);
};

export const GET_ESTUDIANTES_QUERY = (EstatusId, documentado, reinscrito) => {
  const headers = getHeaders();
  const params = R.join(
    '&',
    [
      EstatusId && `filter_by_status=${EstatusId}`,
      documentado && 'filter_by_documentado=true',
      reinscrito && 'filter_by_reinscrito=true',
    ].filter(Boolean)
  );
  return axios.get(`${url}/estudiantes?${params}`, headers);
};

export const GET_ESTUDIANTE = (matricula) => {
  const headers = getHeaders();
  return axios.get(`${url}/estudiantes/${matricula}`, headers);
};

export const GET_BASIC_DATA = () => {
  const headers = getHeaders();
  const getCentros = axios.get(`${url}/centrosEducativos/`, headers);
  const getMaterias = axios.get(`${url}/materias/`, headers);
  const getReligiones = axios.get(`${url}/religiones/`, headers);
  return axios.all([getCentros, getMaterias, getReligiones]);
};

export const PUT_STUDENT_CONTACT = (matricula, data) => {
  const headers = getHeaders();
  return axios.put(
    `${url}/estudiantes/${matricula}/contacto`,
    { data },
    headers
  );
};

export const PUT_STUDENT_ADDRESS = (matricula, data) => {
  const headers = getHeaders();
  return axios.put(
    `${url}/estudiantes/${matricula}/direccion`,
    { data },
    headers
  );
};

export const PUT_STUDENT_ADMIN = (matricula, data) => {
  const headers = getHeaders();
  return axios.put(
    `${url}/estudiantes/${matricula}/administrativo`,
    { data },
    headers
  );
};

export const PUT_STUDENT = (matricula, data) => {
  const headers = getHeaders();
  return axios.put(`${url}/estudiantes/${matricula}`, data, headers);
};

export const PUT_DOCUMENTS_TRACK_ID = (matricula, data) => {
  const headers = getHeaders();
  return axios.put(
    `${url}/estudiantes/${matricula}/administrativo`,
    { data },
    headers
  );
};

export const GET_SUBJECTS = () => {
  const headers = getHeaders();
  return axios.get(`${url}/materias`, headers);
};

export const GET_PERIODOS = () => {
  const headers = getHeaders();
  return axios.get(`${url}/periodos`, headers);
};

export const GET_PERIODO_ACTUAL = () => {
  const headers = getHeaders();
  return axios.get(`${url}/periodos/actual`, headers);
};

export const GET_PERIODO_ANTERIOR = () => {
  const headers = getHeaders();
  return axios.get(`${url}/periodos/anterior`, headers);
};

export const GET_PERIODO_SIGUIENTE = () => {
  const headers = getHeaders();
  return axios.get(`${url}/periodos/siguiente`, headers);
};

export const PUT_SAVE_GRADE = (subjectId, value) => {
  const headers = getHeaders();
  return axios.put(
    `${url}/calificaciones/${subjectId}`,
    {
      data: {
        valor: value,
      },
    },
    headers
  );
};

export const POST_ASSOCIATE_STUDENT_SUBJECT = (
  materia,
  calificacion,
  matricula,
  periodo
) => {
  const headers = getHeaders();
  return axios.post(
    `${url}/estudiantematerias`,
    {
      data: { materia, calificacion, matricula, periodo },
    },
    headers
  );
};

export const DELETE_ASSOCIATE_STUDENT_SUBJECT = (studentSubjectId) => {
  const headers = getHeaders();
  return axios.delete(`${url}/estudiantematerias/${studentSubjectId}`, headers);
};

export const POST_UPDATE_DEUDA = (data) => {
  const headers = getHeaders();
  return axios.post(`${url}/deudas`, data, headers);
};

export const POST_ESTUDIANTE = (data) => {
  const headers = getHeaders();
  return axios.post(`${url}/admin/estudiantes`, data, headers);
};

export const PUT_ESTUDIANTE = (data, matricula) => {
  const headers = getHeaders();
  return axios.put(`${url}/admin/estudiantes/${matricula}`, data, headers);
};

export const DELETE_ESTUDIANTE = (matricula) => {
  const headers = getHeaders();
  return axios.delete(`${url}/admin/estudiantes/${matricula}`, headers);
};

export const POST_UPDATE_MOVIMIENTO = (data) => {
  const headers = getHeaders();
  return axios.post(`${url}/movimientos`, data, headers);
};

export const PUT_MOVIMIENTO = (id, data) => {
  const headers = getHeaders();
  return axios.put(`${url}/movimientos/${id}`, data, headers);
};

export const PUT_REASSIGN = (id, data) => {
  const headers = getHeaders();
  return axios.put(`${url}/movimientos/${id}/reasignar`, data, headers);
};

export const PUT_DEUDA = (id, data) => {
  const headers = getHeaders();
  return axios.put(`${url}/deuda/${id}`, data, headers);
};

export const DELETE_MOVIMIENTO = (id) => {
  const headers = getHeaders();
  return axios.delete(`${url}/movimientos/${id}`, headers);
};

export const DELETE_DEUDA = (id) => {
  const headers = getHeaders();
  return axios.delete(`${url}/deudas/${id}`, headers);
};

export const DELETE_PERIODO = (id) => {
  const headers = getHeaders();
  return axios.delete(`${url}/periodos/${id}`, headers);
};

export const PUT_PERIODO = (id, data) => {
  const headers = getHeaders();
  return axios.put(`${url}/periodos/${id}`, { data }, headers);
};

export const POST_PERIODO = (data) => {
  const headers = getHeaders();
  return axios.post(`${url}/periodos`, { data }, headers);
};

export const POST_PASSWORD_STUDENT = (usuarioId, password, confirmacion) => {
  const headers = getHeaders();
  return axios.post(
    `${url}/usuarios/${usuarioId}/nuevoPassword`,
    { password, confirmacion },
    headers
  );
};

export const POST_UPLOAD_DOCUMENT = (url, formData) => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post(url, formData, config);
};

export const GET_DOWNLOAD_DOCUMENT = (url) => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  return axios.get(url, config);
};

export const POST_UPLOAD_IMAGE = (formData, matricula) => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post(
    `${url}/estudiantes/${matricula}/uploadFoto`,
    formData,
    config
  );
};

export const POST_LINK_CONEKTA = (data) => {
  const headers = getHeaders();
  return axios.post(`${url}/conekta/link_pago`, { data }, headers);
};

export const GET_DESCARGA_CSV = (statusId) => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const urlcsv = `${url}/estudiantes/documentos/csvEstados/${statusId}`;
  return axios.get(urlcsv, config);
};

export const GET_GRADUADOS_CSV = () => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
    params: { periodo: '2024-2' },
  };
  const urlcsv = `${url}/admin/estudiantes/graduados`;
  return axios.get(urlcsv, config);
};

export const GET_POR_GRADUARSE_CSV = () => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const urlcsv = `${url}/admin/estudiantes/porGraduarse`;
  return axios.get(urlcsv, config);
};

export const GET_CSV_MOODLE_IND = (matricula) => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const urlcsv = `${url}/admin/estudiantes/${matricula}/csvMoodle`;
  return axios.get(urlcsv, config);
};

export const GET_CSV_MOODLE_TOTAL = () => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const urlcsv = `${url}/admin/estudiantes/csvMoodle`;
  return axios.get(urlcsv, config);
};

export const GET_NUEVO_INGRESO_CSV = () => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const urlcsv = `${url}/admin/estudiantes/csvInscripcionMoodle`;
  return axios.get(urlcsv, config);
};

export const GET_REINSCRIPCION_CSV = () => {
  const token = sessionManagement?.getToken();
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const urlcsv = `${url}/admin/estudiantes/csvCurricularMoodle`;
  return axios.get(urlcsv, config);
};

export const POST_ENVIA_CORREO = (matricula) => {
  const headers = getHeaders();
  return axios.post(
    `${url}/admin/estudiantes/${matricula}/correoBienvenida`,
    {},
    headers
  );
};

export const UPLOAD_TO_MOODLE = (matricula) => {
  const headers = getHeaders();
  return axios.post(
    `${url}/admin/estudiantes/${matricula}/subirAMoodle`,
    {},
    headers
  );
};

export const PUT_REASSIGN_SUBJECT = (studentSubjectId, data) => {
  const headers = getHeaders();
  return axios.put(
    `${url}/estudiantematerias/${studentSubjectId}/reasignar`,
    data,
    headers
  );
};

export const GET_USERS = ({ offset, limit, filters }) => {
  const headers = getHeaders();
  const params = { offset, limit, ...filters };
  return axios.get(`${url}/usuarios`, { ...headers, params });
};

export const GET_DEBTORS_DATA = (term) => {
  const headers = getHeaders();
  const params = { ...(term && { periodo: term }) };
  return axios.get(`${url}/admin/estudiantes/totalesDeudores`, {
    ...headers,
    params,
  });
};

export const GET_LIST_OF_DEBTORS = ({ offset, limit, filters }) => {
  const headers = getHeaders();
  const params = { offset, limit, ...filters };
  return axios.get(`${url}/admin/estudiantes/deudores`, { ...headers, params });
};

export const GET_GROUPED_BY_STATUS = () => {
  const headers = getHeaders();
  return axios.get(`${url}/admin/estudiantes/agrupadosPorEstatus`, {
    ...headers,
  });
};

export const GET_USERS_BY_STATUS = () => {
  const headers = getHeaders();
  return axios.get(`${url}/usuarios/agrupadosPorEstatus`, {
    ...headers,
  });
};

export const POST_USER = (data) => {
  const headers = getHeaders();
  return axios.post(`${url}/admin/usuarios`, data, headers);
};

export const UPDATE_USER = (id, data) => {
  const headers = getHeaders();
  return axios.put(`${url}/admin/usuarios/${id}`, data, headers);
};

export const DELETE_USER = (id) => {
  const headers = getHeaders();
  return axios.delete(`${url}/admin/usuarios/${id}`, headers);
};
