import PropTypes from 'prop-types';
import { Box, Checkbox, Typography } from '@mui/material';

export const CustomCheckbox = ({ label, ...props }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Checkbox
        {...props}
        sx={{
          color: 'primary.darker',
          '&.Mui-checked': {
            color: 'primary',
          },
        }}
      />
      <Typography variant='body1'>{label}</Typography>
    </Box>
  );
};

CustomCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
};
