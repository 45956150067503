import { AddCircleOutline, Download } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useGetDocument } from '../hooks/useGetDocument';
import { useCallback, useState } from 'react';

export const SectionHeader = ({
  title,
  hasEditPermission,
  registrationId,
  setDialogs,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { getBoletaPDF } = useGetDocument(registrationId, title);

  const handleAddSubject = useCallback((term) => {
    setDialogs((dialogs) => ({ ...dialogs, openCreate: true, term }));
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      marginBottom='16px'>
      <Typography variant='subtitle2' sx={{ color: 'secondary.main' }}>
        Periodo {title}
      </Typography>
      <Box display='flex' columnGap='16px'>
        <Button
          variant='text'
          sx={{ color: 'info.darker' }}
          startIcon={<Download />}
          onClick={handleClick}>
          Boleta EN
        </Button>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <MenuItem>
            <Button
              onClick={() => {
                getBoletaPDF('en', true);
                handleClose();
              }}>
              Firmada
            </Button>
          </MenuItem>
          <MenuItem>
            <Button
              onClick={() => {
                getBoletaPDF('en', false);
                handleClose();
              }}>
              Sin firma
            </Button>
          </MenuItem>
        </Menu>

        <Button
          variant='text'
          sx={{ color: 'primary.darker' }}
          startIcon={<Download />}
          onClick={() => {
            getBoletaPDF('es');
          }}>
          Boleta ES
        </Button>
        {hasEditPermission && (
          <Tooltip title='Añadir materia'>
            <Button
              id='add-subject-button'
              onClick={() => handleAddSubject(title)}>
              <AddCircleOutline />
            </Button>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool,
  registrationId: PropTypes.string.isRequired,
  setDialogs: PropTypes.func.isRequired,
};
