import * as R from 'ramda';
import Confirma from 'utils/Confirma';
import DataDisplay from 'components/DataDisplay';
import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Cancel, Edit, Save } from '@mui/icons-material';
import {
  handleAlerts,
  hasHistorial,
  hasAdeudos,
  defineStatusCardColor,
} from 'utils';
import { GET_STATUS_LIST, PUT_STUDENT_ADMIN } from 'utils/api';
import { ResourcesContext } from 'state/ResourcesProvider';
import palette from 'theme/palette';

const InfoEdit = () => {
  const { state, dispatch } = React.useContext(ResourcesContext);
  const { estudiante } = state;
  const hasHist = hasHistorial(estudiante);
  const historialAcademico = hasHist ? estudiante.Periodos : [];
  const data = {
    EstatusId: estudiante.EstatusId ?? '',
    cicloEscolarInicial: estudiante.cicloEscolarInicial ?? '',
    fechaInscripcion: new Date(estudiante?.fechaInscripcion) ?? '',
    becado: estudiante.becado ?? '',
    moodleId: estudiante.moodleId,
  };

  const [administrativo, setAdministrativo] = React.useState(data);
  const [editInformacion, setEditInformacion] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [adeudos, setAdeudos] = React.useState('No');
  const [entregoActa, setEntregoActa] = React.useState('No');
  const [materiasCursadas, setMateriasCursadas] = React.useState(0);
  const [statusList, setStatusList] = React.useState(null);
  const [filteredStatus, setFilteredStatus] = React.useState(null);

  const { successMessage, errorMessage } = handleAlerts();

  const resetInfo = () => setAdministrativo(data);
  const handleCancel = () => setOpen(true);
  const handleEdit = () => setEditInformacion(true);
  const handleSave = async () => {
    try {
      const response = await PUT_STUDENT_ADMIN(
        estudiante.matricula,
        administrativo
      );
      dispatch({ type: 'SET_ESTUDIANTE', payload: response.data.estudiante });
      successMessage(
        'Se han actualizado los datos administrativos del estudiante'
      );
      setEditInformacion(false);
    } catch (error) {
      errorMessage('Error al actualizar la información');
      setEditInformacion(true);
      console.log(
        'Error',
        error.message || error.response?.data?.message || error
      );
    }
  };

  const handleFechaInsc = (date) =>
    setAdministrativo((prev) => {
      return { ...prev, fechaInscripcion: date };
    });

  const isCursada = (materia) => (!R.isNil(materia.Calificacion) ? 1 : 0);
  const curMats = R.map((periodo) => R.map(isCursada)(periodo.Materias));

  const countCursadas = (estudiante) => {
    return hasHistorial(estudiante)
      ? R.sum(R.flatten(curMats(estudiante.Periodos)))
      : 0;
  };

  React.useEffect(() => {
    if (estudiante) {
      resetInfo();
      const cursadas = countCursadas(estudiante);
      setMateriasCursadas(cursadas);
      setAdeudos(hasAdeudos(estudiante) ? 'Si' : 'No');
      setEntregoActa(
        R.isNil(estudiante.Persona.documentoOficial) ? 'No' : 'Si'
      );
    }
  }, [estudiante]);

  React.useEffect(() => {
    const fetch = async () => {
      if (statusList === null) {
        try {
          const response = await GET_STATUS_LIST();
          const filterStatuses = response?.data?.statuses?.filter(
            (estatus) =>
              estatus.id === 4 ||
              estatus.id === 5 ||
              estatus.id === 6 ||
              estatus.id === 10
          );
          setStatusList(response?.data?.statuses);
          setFilteredStatus(filterStatuses);
        } catch (error) {
          console.log('Error', error);
        }
      }
    };
    fetch();
  }, [statusList]);

  return (
    <>
      <Confirma
        titulo='¿Deseas descartar los cambios?'
        open={open}
        setOpen={setOpen}
        action={() => {
          resetInfo();
          setOpen(false);
          setEditInformacion(false);
        }}
      />
      <Card>
        <CardHeader
          title={
            <Typography variant='subtitle2'>
              Información Escolar del Estudiante
            </Typography>
          }
          action={
            <>
              {editInformacion ? (
                <>
                  <Tooltip title='Guardar edición' placement='top'>
                    <IconButton size='small' onClick={handleSave}>
                      <Save />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Cancelar edición' placement='top'>
                    <IconButton size='small' onClick={handleCancel}>
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title='Editar' placement='top'>
                  <IconButton size='small' onClick={handleEdit}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
        />
        <CardContent>
          <Box
            display='flex'
            flexDirection='column'
            rowGap='24px'
            columnGap='16px'>
            <Typography
              variant='body2'
              color={
                palette[
                  defineStatusCardColor(administrativo.EstatusId, statusList)
                ].dark
              }
              style={{
                fontWeight: 700,
              }}>
              Estatus: &nbsp;
              {
                statusList?.find(
                  (estatus) => estatus.id === administrativo.EstatusId
                )?.descripcion
              }
            </Typography>
            <Box
              display='flex'
              flexDirection={['column', 'column', 'row']}
              rowGap='16px'
              columnGap='16px'
              alignItems='center'>
              <FormControl fullWidth>
                <InputLabel id='select-estatus-label'>Estatus</InputLabel>
                <Select
                  labelId='select-estatus-label'
                  label='Estatus'
                  id='select-estatus'
                  value={administrativo.EstatusId || ''}
                  onChange={(event) => {
                    setAdministrativo((prev) => {
                      return {
                        ...prev,
                        EstatusId: event.target.value,
                      };
                    });
                  }}
                  disabled={!editInformacion}>
                  {filteredStatus?.map((estatus) => (
                    <MenuItem key={estatus.id} value={estatus.id}>
                      {estatus.descripcion}
                    </MenuItem>
                  ))}
                  <MenuItem />
                </Select>
              </FormControl>
              <TextField
                fullWidth
                disabled={!editInformacion}
                id='cicloEscolarInicial'
                label='Ciclo Escolar Inicial'
                value={administrativo.cicloEscolarInicial || ''}
                onChange={(event) => {
                  event.persist();
                  const ciclo = event.target.value;
                  setAdministrativo((prev) => {
                    return {
                      ...prev,
                      cicloEscolarInicial: ciclo !== '' ? ciclo : null,
                    };
                  });
                }}
              />
            </Box>
            <Box
              display='flex'
              flexDirection={['column', 'column', 'row']}
              rowGap='16px'
              columnGap='16px'
              alignItems='center'>
              <LocalizationProvider
                dateAdapter={DateAdapter}
                adapterLocale={esLocale}>
                <DesktopDatePicker
                  label='Fecha de inscripción'
                  inputFormat='dd/MM/yyyy'
                  value={administrativo.fechaInscripcion}
                  disabled={!editInformacion}
                  onChange={handleFechaInsc}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
              <TextField
                select
                fullWidth
                disabled={!editInformacion}
                id='tieneBeca'
                name='tieneBeca'
                label='¿Becado?'
                value={administrativo.becado}
                onChange={(event) => {
                  setAdministrativo((prev) => {
                    return { ...prev, becado: event.target.value };
                  });
                }}>
                <MenuItem value={true}>Sí</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </TextField>
            </Box>
            <Box
              display='flex'
              flexDirection={['column', 'column', 'row']}
              rowGap='16px'
              columnGap='16px'
              alignItems='center'>
              <TextField
                fullWidth
                disabled={!editInformacion}
                id='moddleId'
                label='ID de Moodle'
                value={administrativo.moodleId || ''}
                onChange={(event) => {
                  event.persist();
                  const moodleId = event.target.value;
                  setAdministrativo((prev) => {
                    return {
                      ...prev,
                      moodleId: moodleId !== '' ? moodleId : null,
                    };
                  });
                }}
              />
            </Box>
            <Box
              display='grid'
              gridTemplateColumns='repeat(2, 50%)'
              gridTemplateRows='100%'
              rowGap='16px'
              columnGap='16px'>
              <Box display='flex' columnGap='8px' alignItems='center'>
                <DataDisplay titulo='¿Adeudos?' valor={adeudos} />
              </Box>
              <Box display='flex' columnGap='8px' alignItems='center'>
                <DataDisplay titulo='¿Entregó Acta?' valor={entregoActa} />
              </Box>
            </Box>
            <Box
              display='grid'
              gridTemplateColumns='repeat(2, 50%)'
              gridTemplateRows='100%'
              rowGap='16px'
              columnGap='16px'>
              <Box display='flex' columnGap='8px' alignItems='center'>
                <DataDisplay
                  titulo='Grado'
                  valor={(historialAcademico && historialAcademico.lenght < 1
                    ? 0
                    : historialAcademico.length
                  ).toString()}
                />
              </Box>
              <Box display='flex' columnGap='8px' alignItems='center'>
                <DataDisplay
                  titulo='Materias Cursadas'
                  valor={materiasCursadas}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default InfoEdit;
