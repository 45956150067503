import React, { useEffect } from 'react';
import * as R from 'ramda';
import { Box, Button, Table, TableBody, Typography } from '@mui/material';
import { ResourcesContext } from 'state/ResourcesProvider';
import { hasHistorial } from 'utils';
import { SectionHeader } from './components/SectionHeader';
import { groupByCycle, processed } from './utils';
import { SubjectRow } from './components/SubjectRow';
import { TableHeaders } from './components/TableHeaders';
import { AddSubject } from './components/AddSubject';
import { useGradeActions } from './hooks/useGradeActions';
import { getObject } from 'utils/storage';
import { DeleteSubject } from './components/DeleteSubject';
import { EditTerm } from './components/EditTerm';

const Grades = () => {
  const { state } = React.useContext(ResourcesContext);

  const [editMode, setEditMode] = React.useState(false);
  const [groupsOfSubjects, setGroupsOfSubjects] = React.useState([]);
  const [hasEditPermission, setHasEditPermission] = React.useState(false);
  const [dialogs, setDialogs] = React.useState({
    openCreate: false,
    currentRow: null,
    term: null,
    openDelete: false,
    openCreateWithoutTerm: false,
    openEditTerm: false,
  });

  const { handleSubmit, handleSelect, handleDelete } = useGradeActions({
    dialogs,
    setDialogs,
  });

  const estudiante = state.estudiante;
  const headers = ['Materia', 'Calificación', 'Letra'];

  useEffect(() => {
    if (hasHistorial(estudiante)) {
      const periodos = estudiante.Periodos;
      if (!R.isNil(periodos)) {
        const results = R.flatten(
          R.map((infoPeriodo) =>
            processed(infoPeriodo.Periodo?.periodo)(infoPeriodo.Materias)
          )(periodos)
        );

        setGroupsOfSubjects(groupByCycle(results));
      }
    }
  }, [estudiante]);

  useEffect(() => {
    const roles = getObject('roles');
    if (roles) {
      const hasPermission = R.includes('superusuario', JSON.parse(roles));
      setHasEditPermission(hasPermission);
    }
  }, []);

  return (
    <>
      <AddSubject
        open={dialogs.openCreate || dialogs.openCreateWithoutTerm}
        handleSubmit={handleSubmit}
        handleClose={() =>
          setDialogs((dialogs) => ({
            ...dialogs,
            openCreate: false,
            openCreateWithoutTerm: false,
          }))
        }
        term={dialogs.openCreateWithoutTerm ? null : dialogs.term}
      />
      <DeleteSubject
        open={dialogs.openDelete}
        handleClose={() =>
          setDialogs((dialogs) => ({ ...dialogs, openDelete: false }))
        }
        handleDelete={() => handleDelete(dialogs.currentRow)}
      />
      <EditTerm
        open={dialogs.openEditTerm}
        handleClose={() =>
          setDialogs((dialogs) => ({ ...dialogs, openEditTerm: false }))
        }
        currentRow={dialogs.currentRow}
      />

      {R.isEmpty(groupByCycle) ? (
        <Typography variant='subtitle2'>No hay materias cursadas</Typography>
      ) : (
        <Box display='flex' flexDirection='column' rowGap='24px'>
          <Box
            display='flex'
            flexDirection={['column', 'column', 'row']}
            justifyContent='space-between'
            alignItems={['baseline', 'baseline', 'center']}
            rowGap='16px'
            paddingBottom='16px'>
            <Typography variant='h6' color='primary.darker'>
              Calificaciones
            </Typography>
            {hasEditPermission && (
              <Box display='flex' columnGap='16px'>
                <Button
                  variant='contained'
                  color='info'
                  onClick={() => {
                    setDialogs((dialogs) => ({
                      ...dialogs,
                      openCreateWithoutTerm: true,
                    }));
                  }}>
                  Agregar materias
                </Button>
                <Button
                  variant='contained'
                  color={editMode ? 'secondary' : 'primary'}
                  onClick={() => {
                    setEditMode(!editMode);
                  }}>
                  {editMode ? 'Salir' : 'Editar calificaciones'}
                </Button>
              </Box>
            )}
          </Box>

          {groupsOfSubjects.map((group) => (
            <Box key={group.title} borderBottom='1px solid #aca7b475'>
              <SectionHeader
                title={group.title}
                hasEditPermission={hasEditPermission}
                registrationId={estudiante.matricula}
                setDialogs={setDialogs}
              />
              <Table>
                <TableHeaders headers={headers} />
                <TableBody>
                  {group.subjects.map((subject) => (
                    <SubjectRow
                      key={subject.id}
                      subject={subject}
                      handleSelect={handleSelect}
                      isEdition={editMode}
                      handleDelete={handleDelete}
                      setDialogs={setDialogs}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default Grades;
