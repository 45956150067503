import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
// import options2Fill from '@iconify/icons-eva/options-2-fill';
import folderFill from '@iconify/icons-eva/folder-fill';
// import flashFill from '@iconify/icons-eva/flash-fill';
import calendar from '@iconify/icons-eva/calendar-fill';
import book from '@iconify/icons-eva/book-fill';
import personBook from '@iconify/icons-ic/book';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'usuarios',
    path: '/c/usuarios',
    icon: getIcon(peopleFill),
    roles: ['superusuario', 'sistemas'],
  },
  {
    title: 'estudiantes',
    path: '/c/estudiantes',
    icon: getIcon(personBook),
    roles: ['superusuario', 'administrativo', 'sistemas', 'tutor'],
  },
  {
    title: 'administración escolar',
    path: '/c/adminEscolar',
    icon: getIcon(folderFill),
    roles: ['superusuario', 'administrativo', 'sistemas'],
    subpages: [
      {
        title: 'materias',
        path: '/c/adminEscolar/materias',
        icon: getIcon(book),
        roles: ['superusuario', 'administrativo', 'sistemas'],
      },
      {
        title: 'periodos',
        path: '/c/adminEscolar/periodos',
        icon: getIcon(calendar),
        roles: ['superusuario', 'administrativo', 'sistemas'],
      },
    ],
  },
  {
    title: 'dashboard',
    path: '/c/dashboard',
    icon: getIcon(pieChart2Fill),
    roles: ['superusuario'],
  },

  // {
  //   title: 'configuración',
  //   path: '/c/config',
  //   icon: getIcon(options2Fill),
  //   roles: ['superusuario', 'administrativo', 'sistemas'],
  // },

  // {
  //   title: 'administración general',
  //   path: '/login',
  //   icon: getIcon(flashFill),
  //   roles: ['superusuario', 'sistemas'],
  // },
];

export default sidebarConfig;
