import React, { useEffect } from 'react';
import * as R from 'ramda';
import Confirma from 'utils/Confirma';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import SearchNotFound from 'components/SearchNotFound';
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from 'components/_dashboard/user';
import { ResourcesContext } from 'state/ResourcesProvider';
import {
  GET_ESTUDIANTES,
  GET_DESCARGA_CSV,
  DELETE_ESTUDIANTE,
  GET_GRADUADOS_CSV,
  GET_STATUS_LIST,
  GET_BASIC_DATA,
} from 'utils/api';
import { handleAlerts } from 'utils';
import download from 'downloadjs';
import { useQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'hooks/useDebounce/useDebounce';
import { EmailOutlined, Phone } from '@mui/icons-material';

const TABLE_HEAD = [
  {
    id: 'nombreCompleto',
    label: 'Nombre',
    alignRight: false,
    clickable: false,
  },
  { id: 'matricula', label: 'Matricula', alignRight: false, clickable: true },
  { id: 'contactoEstudiante', label: 'Contacto estudiante', clickable: false },
  {
    id: 'contactoTutor',
    label: 'Contacto tutor',
    alignRight: false,
    clickable: false,
  },
  { id: '' },
];

const StudentLink = styled(RouterLink)(() => ({
  textDecoration: 'inherit',
  color: 'inherit',
  '&:hover': { textDecoration: 'underline' },
}));

const Estudiantes = () => {
  const { state, dispatch } = React.useContext(ResourcesContext);
  const {
    filters: {
      filterName,
      filterType,
      selectedStatusFilter,
      checkedFilters,
      filterBySex,
      filterByReligion,
      filterByCenter,
      filterByCountry,
    },
  } = state;
  const { successMessage, errorMessage } = handleAlerts();
  const [order, setOrder] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [matriculaDelete, setMatriculaDelete] = React.useState('');
  const [openConfirma, setOpenConfirma] = React.useState(false);

  const queryClient = useQueryClient();

  const handleDeleteStudent = async () => {
    try {
      await DELETE_ESTUDIANTE(matriculaDelete);
      queryClient.invalidateQueries('students');
    } catch (error) {
      console.log('Error', error.response.data?.message || error);
      errorMessage(error.message || error.response?.data?.message || error);
    } finally {
      setOpenConfirma(false);
    }
  };

  const removeUser = (matricula) => () => {
    setOpenConfirma(true);
    setMatriculaDelete(matricula);
  };

  // Queries
  const debouncedValue = useDebounce(filterName, 500);
  const otherFilters = checkedFilters?.map((filter) => ({
    [filter]: true,
  }));
  const { data: studentsData } = useQuery(
    [
      'students',
      rowsPerPage,
      state.page,
      debouncedValue,
      filterType,
      selectedStatusFilter,
      checkedFilters,
      order,
      orderBy,
      filterBySex,
      filterByReligion,
      filterByCenter,
      filterByCountry,
    ],
    () =>
      GET_ESTUDIANTES({
        limit: rowsPerPage,
        offset: state.page * rowsPerPage,
        filters: {
          ...(filterType && debouncedValue && { [filterType]: debouncedValue }),
          EstatusId: selectedStatusFilter,
          ...R.mergeAll(otherFilters),
          ...(order && { order_by: order }),
          ...(orderBy && { sort_by: orderBy }),
          ...(filterBySex && { sexo: filterBySex }),
          ...(filterByReligion && { ReligionId: filterByReligion }),
          ...(filterByCenter && { CentroEducativoId: filterByCenter }),
          ...(filterByCountry && { PaisId: filterByCountry }),
        },
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event, newPage) => {
    dispatch({ type: 'SET_PAGE', payload: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch({ type: 'SET_PAGE', payload: 0 });
  };

  const emptyRows =
    state.page > 0
      ? Math.max(
          0,
          (1 + state.page) * rowsPerPage -
            studentsData?.data?.estudiantes?.length
        )
      : 0;

  const isUserNotFound = studentsData?.data?.estudiantes?.rows?.length === 0;
  const [open, setOpen] = React.useState(false);

  const handleGraduadosCSV = async () => {
    try {
      const response = await GET_GRADUADOS_CSV({
        previousTerm: state.periodoAnterior.periodo,
      });
      download(response.data, 'graduados.csv', 'application/csv');
      successMessage('Se descargó correctamente el CSV');
    } catch (error) {
      errorMessage(error.message);
      console.log('Error', error?.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await GET_STATUS_LIST();
        dispatch({ type: 'SET_STATUS_LIST', payload: response.data.statuses });
      } catch (error) {
        console.log('Error', error.response.data?.message || error);
      }
    };
    if (state.statusList === null) {
      fetch();
    }
  }, [state.statusList]);

  useEffect(async () => {
    try {
      const responses = await GET_BASIC_DATA();
      dispatch({
        type: 'FETCH_BASIC_DATA',
        payload: {
          centros: R.map((centro) => ({
            value: centro.id,
            nombre: centro.titulo,
            id: centro.id,
          }))(responses[0].data.centros),
          materias: responses[1].data.materias,
          religiones: responses[2].data.religiones,
        },
      });
    } catch (error) {
      console.log('Error', error.response.data?.message || error);
    }
  }, []);

  return (
    <Page title='Estudiantes | Control Escolar'>
      <Container maxWidth='xl'>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          flexDirection={['column', 'column', 'row']}
          rowGap='16px'
          mb={5}>
          <Typography variant='h4' gutterBottom>
            Estudiantes
          </Typography>
          <Button
            variant='contained'
            startIcon={<DownloadIcon />}
            onClick={() => setOpen(true)}>
            Descargar CSV de estudiantes
          </Button>
          <DownloadCSV open={open} handleClose={() => setOpen(false)} />

          <Button variant='contained' onClick={handleGraduadosCSV}>
            Descargar graduados
          </Button>

          <Button
            variant='contained'
            component={RouterLink}
            to='/c/estudiante/nuevo'
            startIcon={<Icon icon={plusFill} />}>
            Nuevo estudiante
          </Button>
        </Stack>

        <Confirma
          open={openConfirma}
          setOpen={setOpenConfirma}
          action={handleDeleteStudent}
          titulo='¿Desea eliminar al estudiante?'
        />
        <UserListToolbar statusList={state.statusList} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {studentsData?.data?.estudiantes?.rows?.map((row) => {
                    const {
                      PersonaId,
                      nombreCompleto,
                      matricula,
                      email,
                      telefono,
                      telefonoTutor,
                      correoTutor,
                    } = row;

                    const name = nombreCompleto;

                    return (
                      <TableRow key={PersonaId} tabIndex={-1}>
                        <TableCell
                          component='th'
                          scope='row'
                          style={{ paddingLeft: '16px' }}>
                          <Typography
                            variant='subtitle2'
                            color='primary.darker'
                            noWrap>
                            <StudentLink to={`/c/estudiante/${matricula}`}>
                              {name.toUpperCase()}
                            </StudentLink>
                          </Typography>
                        </TableCell>
                        <TableCell align='left'>{matricula}</TableCell>
                        <TableCell align='left'>
                          <Box display='grid' gap='8px'>
                            <Box display='flex' alignItems='center' gap='8px'>
                              <EmailOutlined color='secondary' />
                              <Typography variant='body2'>{email}</Typography>
                            </Box>
                            <Box display='flex' alignItems='center' gap='8px'>
                              <Phone color='secondary' />
                              <Typography variant='body2'>
                                {telefono}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align='left'>
                          <Box display='grid' gap='8px'>
                            <Box display='flex' alignItems='center' gap='8px'>
                              <EmailOutlined color='secondary' />
                              <Typography variant='body2'>
                                {correoTutor}
                              </Typography>
                            </Box>
                            {telefonoTutor && (
                              <Box display='flex' alignItems='center' gap='8px'>
                                <Phone color='secondary' />
                                <Typography variant='body2'>
                                  {telefonoTutor}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </TableCell>

                        <TableCell align='right'>
                          <UserMoreMenu onClickDelete={removeUser(matricula)} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={studentsData?.data?.estudiantes?.count || 0}
            rowsPerPage={rowsPerPage}
            page={state.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Estudiantes por página'
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} de ${count}`
            }
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
    </Page>
  );
};

const DownloadCSV = ({ open, handleClose }) => {
  const [statusId, setStatusId] = React.useState(1);
  const { state } = React.useContext(ResourcesContext);
  const { statusList } = state;

  const { successMessage, errorMessage } = handleAlerts();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await GET_DESCARGA_CSV(statusId);

      download(
        response?.data,
        `estudiantes-estatus-${
          statusList.find((estatus) => estatus.id === statusId).descripcion
        }.csv`,
        'application/csv'
      );
      successMessage('Se descargó correctamente el CSV');
    } catch (error) {
      errorMessage(error.message);
      console.log('Error', error?.response?.data?.message || error.message);
    } finally {
      setStatusId(1);
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm'>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Descarga de CSV de información de estudiantes</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} alignItems='center'>
            <Grid item xs={12}>
              <DialogContentText>
                Selecciona el filtro de estudiantes para descargar el CSV
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='select-estatus-label'>Estatus</InputLabel>
                <Select
                  labelId='select-estatus-label'
                  label='Estatus'
                  id='select-estatus'
                  value={statusId}
                  onChange={(event) => setStatusId(event.target.value)}>
                  {statusList?.map((estatus) => (
                    <MenuItem key={estatus.id} value={estatus.id}>
                      {estatus.descripcion}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant='contained' color='primary' type='submit'>
            Descargar CSV
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Estudiantes;
